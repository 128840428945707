export const columnsRoles = [
  { text: 'Compania', value: 'IdCompania' },
  { text: 'Punto de venta', value: 'NombreTienda' },
  { text: 'Proveedor Tecnológico', value: 'ProveedorTecnologico' },
  { text: 'Prefijo', value: 'Prefijo' },
  { text: 'Consecutivo Factura', value: 'CosecutivoDocumento' },
  { text: 'Total Monto Ppal', value: 'TotalMontoEncabezado' },
  { text: 'Estado', value: 'Estado' },
  { text: 'Observaciones', value: 'Mensaje' },
  { text: 'Reglas DIAN', value: 'reglasDian' },
  { text: 'Origen Transacción', value: 'DescripcionOrigenTrans' },
  { text: 'Fecha Factura', value: 'FechaFactura' },
  { text: 'QR', value: 'QR' },
  { text: 'CUFE', value: 'Cufe' },
  { text: 'PDF', value: 'pdf' },
  { text: 'Reenviar Factura', value: 'reenviarEmail' },
  { text: 'Codigo Respuesta', value: 'CodigoRespuesta' },
  { text: 'Fecha Envio', value: 'FechaEnvio' },
  { text: 'Fecha Sincronización', value: 'FechaRespuesta' },

]

if (JSON.parse(atob(localStorage.getItem('uid'))).rol === 'Administrador') {
  columnsRoles.push({ text: 'Reprocesar Factura ', value: 'reprocesarFactura' })
  columnsRoles.push({ text: 'XML Hiopos', value: 'xml' })
  columnsRoles.push({ text: 'Datos Enviados', value: 'JsonHKA' })
}

