var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.copyAlert),expression:"copyAlert"}],attrs:{"type":"info","text":""}},[_vm._v(" "+_vm._s(_vm.msgjsoncopiado)+" ")]),(_vm.$store.state.invoice.listInvoice.Mensaje.length > 0)?_c('base-card',[_c('v-card-title',[_vm._v(" Listado de Facturas Electrónicas "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.$store.state.invoice.listInvoice.Mensaje,"search":_vm.search,"footer-props":{
            'items-per-page-text': 'filas por pagina',
          }},scopedSlots:_vm._u([{key:"item.TotalMontoEncabezado",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.TotalMontoEncabezado))+" "),(item.TotalMontoEncabezado > 0 && _vm.progressUnlockInvoice == false && _vm.isAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","large":"","color":"orange darken-2"},on:{"click":function($event){return _vm.unlockInvoice(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock ")])]}}],null,true)},[_c('span',[_vm._v("Desbloquear")])]):_vm._e()],1),(_vm.progressUnlockInvoice == true)?_c('v-progress-linear',{attrs:{"color":"red darken-4","indeterminate":"","rounded":"","height":"6"}}):_vm._e()]}},{key:"item.Estado",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'W')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"success darken-2","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-check ")])]}}],null,true)},[_c('span',[_vm._v("Procesado")])]):(item.Estado === 'P')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red darken-2","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-alert ")])]}}],null,true)},[_c('span',[_vm._v("Pendiente")])]):(item.Estado === 'E')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red darken-2","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-box ")])]}}],null,true)},[_c('span',[_vm._v("Pendiente")])]):_vm._e()]}},{key:"item.pdf",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'W')?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"1500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"red darken-4"},on:{"click":function($event){return _vm.getDataPDF(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-pdf-box ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-actions',{staticClass:"justify-end"},[_c('v-icon',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" mdi-close-thick ")])],1),_c('v-toolbar',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"dark":""}},[_vm._v(" PDF FACTURA "+_vm._s(item.CosecutivoDocumento)+" "),_c('v-icon',{staticClass:"pa-4",on:{"click":function($event){return _vm.downloadPDF(item.CosecutivoDocumento)}}},[_vm._v(" mdi-download-circle ")])],1),_c('v-card-text',[(_vm.progress == true)?_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Consultando y Visualizando PDF ")]),_c('v-progress-linear',{attrs:{"color":"red darken-4","indeterminate":"","rounded":"","height":"6"}})],1)],1):_vm._e(),_c('div',{staticClass:"text-h6"},[_c('h5',{staticStyle:{"text-align":"center"}},[_c('b',[_vm._v(_vm._s(_vm.msgPdf))])]),_vm._l((_vm.numPages),function(i){return _c('pdf',{key:i,ref:"myPdfComponent",refInFor:true,attrs:{"src":_vm.src,"page":i}})})],2)],1)],1)]}}],null,true)}):_vm._e()]}},{key:"item.reprocesarFactura",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'P' || item.Estado === 'E')?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"success darken-4"},on:{"click":function($event){return _vm.reprocessInvoice(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-move ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" PROCESAMIENTO DE FACTURA - "+_vm._s(item.CosecutivoDocumento)+" ")]),_c('v-card-text',[(_vm.progress == true)?_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Reprocesando factura . Por favor espere un momento ")]),_c('v-progress-linear',{attrs:{"color":"red darken-4","indeterminate":"","rounded":"","height":"6"}})],1)],1):_vm._e(),_c('div',{staticClass:"text-h6"},[(_vm.reponseReprocessData != '')?_c('json-viewer',{attrs:{"value":_vm.reponseReprocessData,"copyable":"true"},scopedSlots:_vm._u([(_vm.progress == false)?{key:"copy",fn:function(){return [_vm._v(" Copiar "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")])]},proxy:true}:null],null,true)}):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cerrar ")])],1)],1)]}}],null,true)}):_vm._e()]}},{key:"item.reenviarEmail",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'W')?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"orange darken-3"},on:{"click":function($event){return _vm.clearEmail()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-email-fast ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"dark":""}},[_vm._v(" ENVIAR CORREO DE FACTURA - "+_vm._s(item.CosecutivoDocumento)+" ")]),_c('v-card-text',{staticStyle:{"text-align":"center","margin-top":"40px"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{ref:"email",attrs:{"label":"Correo","prepend-inner-icon":"mdi-email","clearable":"","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_vm._v(" "+_vm._s(_vm.msgResponseEmail)+" ")],1),(_vm.progress == true)?_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Enviando Correo ")]),_c('v-progress-linear',{attrs:{"color":"red darken-4","indeterminate":"","rounded":"","height":"6"}})],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"text":""},on:{"click":function($event){return _vm.sendEmail(item)}}},[_vm._v(" Enviar ")])],1)],1)]}}],null,true)}):_vm._e()]}},{key:"item.reglasDian",fn:function(ref){
          var item = ref.item;
return [(item.Estado == 'P')?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-book-open ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" REGLAS DIAN - FACTURA "+_vm._s(item.CosecutivoDocumento)+" ")]),_c('v-card-text',{staticStyle:{"text-align":"center","margin-top":"40px"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("REGLAS DE VALIDACÍON")]),_vm._v(" "+_vm._s(item.reglasValidacionDIAN)+" ")],1)],1),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" REGLAS DE NOTIFICACÍON ")]),_vm._v(" "+_vm._s(item.reglasNotificacionDIAN)+" ")],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cerrar ")])],1)],1)]}}],null,true)}):_vm._e()]}},{key:"item.QR",fn:function(ref){
          var item = ref.item;
return [(item.Estado == 'W' && item.QR != null)?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"black darken-3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-qrcode-scan ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"dark":""}},[_vm._v(" QR FACTURA ELECTRÓNICA - FACTURA "+_vm._s(item.CosecutivoDocumento)+" ")]),_c('v-card-text',{staticStyle:{"text-align":"center","margin-top":"40px"}},[_c('qrcode',{attrs:{"size":_vm.size,"value":item.QR}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cerrar ")])],1)],1)]}}],null,true)}):_vm._e()]}},{key:"item.Cufe",fn:function(ref){
          var item = ref.item;
return [(item.Estado == 'W')?_c('v-icon',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.Cufe),expression:"item.Cufe",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")]):_vm._e()]}},{key:"item.xml",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"success darken-3"},on:{"click":function($event){return _vm.downloadXML(item.xml, item.CosecutivoDocumento)}}},'v-icon',_vm.attrs,false),_vm.on),[_vm._v(" mdi-xml ")])]}},{key:"item.NombreTienda",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.NombreTienda)+" ")]}},{key:"item.ProveedorTecnologico",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNameProoveedorTecnogico(item.ProveedorTecnologico))+" ")]}},{key:"item.JsonHKA",fn:function(ref){
          var item = ref.item;
return [_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-code-json ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-actions',{staticClass:"justify-end"},[_c('v-icon',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" mdi-close-thick ")])],1),_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" DATOS ENVIADOS EN FORMATO JSON - FACTURA "+_vm._s(item.CosecutivoDocumento)+" ")]),_c('v-card-text',[_c('div',{staticClass:"text-h6"},[_c('json-viewer',{attrs:{"value":JSON.parse(item.JsonHKA),"copyable":"true"},scopedSlots:_vm._u([{key:"copy",fn:function(){return [_vm._v(" Copiar "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")])]},proxy:true}],null,true)})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cerrar ")])],1)],1)]}}],null,true)})]}}],null,true)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }